<template>
  <b-container class="mt-4">
    <b-card header-tag="header" header-class="header">
      <template #header>
        <label v-text="'Karşılaşmalar'" class="mr-2 text-white"></label>
        <b-button
          variant="primary"
          :to="{ name: 'FederationGroups', params: { id: $route.params.id } }"
          v-if="hasRole(['federation'])"
          >Yarışma Gruplarını Getir</b-button
        >
      </template>
      <b-modal
        id="draw-modal"
        ref="modal"
        title="Kura Çekiliyor"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
      >
        <b-alert
          class="spacing"
          variant="warning"
          show
          v-if="!getSingleCompetition.endingDate"
        >
          İlk çekilen kura kulüplerin yarışma grubu atamalarını kapatacaktır!
        </b-alert>
        <form ref="form" @submit.stop.prevent="handleSubmit">
          Karşılaşmanın yapılacağı tarih:
          <b-form-input
            id="name-input"
            v-model="matchDate"
            :state="drawValidity"
            type="date"
            required
          ></b-form-input>
        </form>
      </b-modal>

      <b-modal id="revert-modal" title="Dikkat!" @ok="revertLastDraw">
        <p class="my-4">
          En son çekilen kurayı geri almak istediğinizden emin misiniz?
        </p>
        <template #modal-footer="{ ok, cancel }">
          <b-button size="sm" variant="secondary" @click="cancel()">
            İptal
          </b-button>
          <b-button size="sm" variant="danger" @click="ok()"> Evet </b-button>
        </template>
      </b-modal>

      <br />
      <b-alert show>
        <div class="font-weight-bold">LÜTFEN OKUYUNUZ!!!</div>
        <br />
        <div>
          1-KURAYI ÇEK TUŞUNA TIKLADIĞINIZDA EĞER HAZIRDA KARŞILAŞMA VARSA, O
          TURUN GEÇENLERİ İLE SONRAKİ TURUN KURASI ÇEKİLİR. YOKSA TÜM
          KATILIMCILAR İLE KURA ÇEKİLİR
        </div>
        <div>
          2-DEĞİŞİKLİKLERİ KAYDET TUŞUNA TIKLADIĞINIZDA SEÇTİĞİNİZ GRUPTA YAPMIŞ
          OLDUĞUNUZ DEĞİŞİKLİKLER KAYIT EDİLİR.
        </div>
        <div>
          3-KURA ÇEKİLDİKTEN SONRA GERİ ALINMASI GEREKİYORSA "GERİ AL" TUŞU İLE
          BİR ÖNCEKİ TURA DÖNEBİLİRSİNİZ.
        </div>
      </b-alert>

      <b-row>
        <b-col>
          <b-form-select
            :options="options.gender"
            v-model="gender"
          ></b-form-select>
        </b-col>
        <b-col>
          <b-form-select
            :options="options.category"
            v-model="category"
            value-field="id"
            text-field="title"
          ></b-form-select>
        </b-col>
        <b-col>
          <b-form-select
            :options="options.distance"
            v-model="distance"
            value-field="id"
            text-field="title"
          ></b-form-select>
        </b-col>
        <b-col>
          <b-form-select
            :options="options.boatType"
            v-model="boatType"
            value-field="id"
            v-if="getBranchName !== 'Rafting'"
            text-field="title"
          ></b-form-select>
        </b-col>
        <b-col cols="auto" v-if="hasRole(['federation'])">
          <b-button variant="primary" v-b-modal.draw-modal>
            Kurayı Çek
          </b-button>
        </b-col>
        <b-col cols="auto" v-if="hasRole(['federation'])">
          <b-button variant="danger" v-b-modal.revert-modal> Geri Al </b-button>
        </b-col>
        <b-col cols="auto">
          <b-button variant="secondary" @click="saveChanges">
            Değişiklikleri Kaydet
          </b-button>
        </b-col>
        <!-- <b-col>
          <b-button variant="secondary"> Tümünü Yazdır </b-button>
        </b-col> -->
      </b-row>
      <hr />

      <MatchesTable
        :tableFields="matchFields"
        :tableItems="matches"
        @openDetail="openDetail"
        editable
      ></MatchesTable>
    </b-card>
  </b-container>
</template>

<script>
import MatchesTable from "../../components/tables/MatchesTable.vue";
import options from "@/store/constants/personel.js";
import { mapGetters } from "vuex";
export default {
  components: {
    MatchesTable,
  },
  computed: {
    ...mapGetters([
      "getMatches",
      "getMatchFilter",
      "getManagementItems",
      "getSingleCompetition",
      "getMatchFilterOptions",
    ]),
    getBranchName: {
      get() {
        const branch = this.getSingleCompetition.branch;
        const managementItems = this.getManagementItems;
        return managementItems.find((item) => item.id == branch)?.title;
      },
    },
    options: {
      get() {
        const manItems = this.getManagementItems;
        return {
          gender: [{ text: "Cinsiyet", value: "" }].concat(
            options.genderOptions
              .concat({
                text: "Mix",
                value: "mixed",
              })
              .filter((e) =>
                this.getMatchFilterOptions.gender.includes(e.value)
              )
          ),
          category: [{ title: "Kategori", id: "" }].concat(
            manItems.filter(
              (e) =>
                e.category == 2 &&
                this.getMatchFilterOptions.category.includes(e.id)
            )
          ),
          boatType: [{ title: "Tekne Sınıfı", id: "" }].concat(
            manItems.filter(
              (e) =>
                e.category == 6 &&
                this.getMatchFilterOptions.boatType.includes(e.id)
            )
          ),
          distance: [
            {
              title: this.getBranchName === "Rafting" ? "Disiplin" : "Mesafe",
              id: "",
            },
          ].concat(
            manItems.filter(
              (e) =>
                e.category == 4 &&
                this.getMatchFilterOptions.distance.includes(e.id)
            )
          ),
        };
      },
    },
    matches: {
      get() {
        return this.getMatches.map((e) => {
          e.personels = [];
          e.backupables = [
            {
              text: "Seçin",
              value: 0,
            },
          ];
          e.club = { name: e.group.clubRel?.name };
          for (let i = 1; i < 5; i++) {
            const personelId = e.group["personelId" + i];
            if (personelId) {
              const personel = e.group["personel" + i];
              if (personelId == e.group.backupPosition)
                e.personels.push(e.group.personelBackup);
              else if (personelId == e.group.backupPosition2) {
                e.personels.push(e.group.personelBackup2);
              } else {
                e.personels.push(personel);
              }

              e.backupables.push({
                text: personel.name + " " + personel.surname,
                value: personelId,
              });
            }
          }
          e.backupPosition = e.group.backupPosition;
          e.backupPosition2 = e.group.backupPosition2;

          return e;
        });
      },
    },
    gender: {
      get() {
        return this.matchFilter.gender;
      },
      set(value) {
        this.matchFilter.gender = value;
        this.$store.commit("updateMatchFilter", this.matchFilter);
        this.$store.dispatch("initMatches", {
          competitionId: this.$route.params.id,
        });

        this.setupBackupFields();
      },
    },
    distance: {
      get() {
        return this.matchFilter.distance;
      },
      set(value) {
        this.matchFilter.distance = value;
        this.$store.commit("updateMatchFilter", this.matchFilter);
        this.$store.dispatch("initMatches", {
          competitionId: this.$route.params.id,
        });
      },
    },
    category: {
      get() {
        return this.matchFilter.category;
      },
      set(value) {
        this.matchFilter.category = value;
        this.$store.commit("updateMatchFilter", this.matchFilter);
        this.$store.dispatch("initMatches", {
          competitionId: this.$route.params.id,
        });
      },
    },
    boatType: {
      get() {
        return this.matchFilter.boatType;
      },
      set(value) {
        this.matchFilter.boatType = value;
        this.$store.commit("updateMatchFilter", this.matchFilter);
        this.$store.dispatch("initMatches", {
          competitionId: this.$route.params.id,
        });
      },
    },
  },
  created() {
    this.$store.dispatch("initManagement");
    this.$store.dispatch("initMatches", {
      competitionId: this.$route.params.id,
    });
    this.$store.dispatch("getMatchFilterOptionsFromServer", {
      competitionId: this.$route.params.id,
    });
    this.$store.dispatch("initSingleCompetition", this.$route.params.id);
  },
  watch: {
    getManagementItems: {
      immediate: true,
      handler(managements) {
        if (
          this.getSingleCompetition?.branch ==
          managements.find((e) => e.title === "Rafting")?.id
        ) {
          this.boatType = managements.find((e) => e.title === "4+1").id;
        }
      },
    },
    getSingleCompetition: {
      immediate: true,
      handler(competition) {
        if (
          competition.branch ==
          this.getManagementItems.find((e) => e.title === "Rafting")?.id
        ) {
          this.boatType = this.getManagementItems.find(
            (e) => e.title === "4+1"
          ).id;
        }
        if (competition.election.option == "1") {
          console.log(
            this.getManagementItems.find((e) => e.title === "4+1").id
          );
          this.boatType = this.getManagementItems.find(
            (e) => e.title === "4+1"
          ).id;
          const description = this.matchFields.pop();
          this.matchFields.push(
            {
              key: "finishTime2",
              label: "Zaman 2",
            },
            description
          );
        }
      },
    },
  },

  data() {
    return {
      matchFilter: {
        gender: "",
        boatType: "",
        category: "",
        distance: "",
      },
      form: null,
      matchFields: [
        {
          key: "id",
          label: "Sıra",
        },
        {
          key: "names",
          label: "Ad Soyad",
        },
        {
          key: "matchDate",
          label: "Tarih Saat",
        },
        {
          key: "finishTime",
          label: "Zaman",
        },
        {
          key: "eliminationReason",
          label: "Açıklama",
        },
      ],
      matchDate: "",
      drawValidity: null,
    };
  },
  methods: {
    hasRole(roles) {
      let vals = [];
      roles.forEach((role) => {
        if (role == "federation") {
          vals.push(this.$store.getters.checkToken.isFederation);
        } else if (role == "personel") {
          vals.push(this.$store.getters.checkToken.isPersonel);
        } else if (role == "club") {
          vals.push(this.$store.getters.checkToken.isClub);
        } else if (role == "provincialRepresentative") {
          vals.push(this.$store.getters.checkToken.isProvincialRepresentative);
        }
      });
      return vals.includes(true);
    },
    setupBackupFields() {
      if (this.getBranchName === "Rafting") {
        if (!this.matchFields.find((mf) => mf.key === "backupPosition")) {
          this.matchFields.push({
            key: "backupPosition",
            label: "Yedek Pozisyonu",
          });
        }

        if (this.matchFilter.gender === "mixed") {
          if (!this.matchFields.find((mf) => mf.key === "backupPosition2")) {
            this.matchFields.push({
              key: "backupPosition2",
              label: "Yedek Pozisyonu 2",
            });
          }
        } else {
          this.matchFields = this.matchFields.filter(
            (mf) => mf.key !== "backupPosition2"
          );
        }
      }
    },
    saveChanges() {
      if (this.getBranchName === "Rafting") {
        this.$store.dispatch("editCompetitionGroups", {
          groups: this.matches.map((match) => ({
            ...match.group,
            backupPosition: match.backupPosition,
            backupPosition2: match.backupPosition2,
          })),
        });
      }
      this.updateMatches();
    },
    updateMatches() {
      this.$store.dispatch("editMatches", {
        competitionId: this.$route.params.id,
        matches: this.matches,
      });
    },
    openDetail(userId) {
      this.$router.push({
        name: "PersonelDetail",
        params: { id: userId },
      });
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.drawValidity = valid;
      return valid;
    },
    resetModal() {
      this.matchDate = "";
      this.drawValidity = null;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.$store
        .dispatch("drawMatches", {
          competitionId: this.$route.params.id,
          matchDate: this.matchDate,
        })
        .then(() => {
          this.$store.dispatch("notification/setNotifications", [
            {
              title: "Kura çekildi!",
              body: "Kura başarıyla çekildi",
              type: "success",
            },
          ]);
          this.$store.dispatch("initSingleCompetition", this.$route.params.id);
        });
      this.$nextTick(() => {
        this.$bvModal.hide("draw-modal");
      });
    },
    revertLastDraw() {
      this.$store.dispatch("deleteLastDraw", {
        competitionId: this.$route.params.id,
      });
    },
  },
};
</script>

<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
</style>