<template>
  <div>
    <div v-if="tableItems.length == 0">
      <b-alert show variant="warning">Kayıt bulunamadı</b-alert>
    </div>
    <div v-else>
      <b-table-simple>
        <b-thead>
          <b-tr>
            <b-th v-for="field in tableFields" :key="field.key">
              {{ field.label }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="match in matches" :key="match.id">
            <b-td
              :colspan="tableFields.length"
              v-if="match.text"
              class="text-center font-weight-bold"
              variant="danger"
            >
              {{ match.text }}
            </b-td>
            <b-td
              :colspan="tableFields.length"
              v-else-if="match.groupText"
              class="text-center font-weight-bold"
              variant="warning"
            >
              {{ match.groupText }}
            </b-td>
            <b-td v-for="field in tableFields" :key="field.key" v-else>
              <div v-if="field.key == 'names'">
                <div
                  style="width: 24rem"
                  v-for="user in match.personels"
                  :key="user.id"
                >
                  <router-link
                    v-text="user.name + ' ' + user.surname"
                    :to="{ name: 'PersonelDetail', params: { id: user.id } }"
                  ></router-link>
                </div>
                <small
                  class="text-danger"
                  v-if="match.clubRel && match.clubRel.name"
                >
                  {{ match.clubRel.name }}
                </small>
              </div>
              <b-input
                v-model="match.matchDate"
                type="date"
                v-else-if="field.key == 'matchDate'"
              ></b-input>
              <b-input
                type="time"
                step="0.001"
                v-model="match.finishTime"
                v-else-if="field.key == 'finishTime'"
              ></b-input>
              <b-input
                type="time"
                step="0.001"
                v-model="match.finishTime2"
                v-else-if="field.key == 'finishTime2'"
              ></b-input>
              <b-select
                :options="eliminationReason"
                v-model="match.eliminationReason"
                v-else-if="field.key == 'eliminationReason'"
              ></b-select>
              <b-select
                :options="match.backupables"
                v-model="match.backupPosition"
                v-else-if="
                  field.key == 'backupPosition' && match.group.personelIdBackup
                "
              ></b-select>
              <b-select
                :options="match.backupables"
                v-model="match.backupPosition2"
                v-else-if="
                  field.key == 'backupPosition2' &&
                  match.group.personelIdBackup2
                "
              ></b-select>
              <b-badge variant="secondary" v-else-if="field.key == 'id'">{{
                match.rank + 1
              }}</b-badge>
              <div v-else>Yok</div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
import eliminationReason from "@/store/constants/eliminationReason";
export default {
  name: "MatchesTable",
  props: ["tableFields", "tableItems"],

  computed: {
    matches: {
      get() {
        const array = [];
        this.tableItems.forEach((element) => {
          if (!array[element.round]) {
            array[element.round] = [];
          }
          array[element.round].push(element);
        });
        array.forEach((round) => {
          const arr = [];
          round.forEach((element) => {
            if (!arr[element.groupId]) {
              arr[element.groupId] = [];
            }
            arr[element.groupId].push(element);
          });
          array[round[0].round] = arr;
        });
        return array
          .reverse()
          .map((element, index) => {
            return [
              {
                text: `TUR: ${array.length - index - 1}`,
              },
              element.map((ele, inde) => {
                return [
                  {
                    groupText: `Grup : ${inde}`,
                  },
                  ele,
                ];
              }),
            ];
          })
          .flat(4);
      },
    },
  },
  data() {
    return {
      eliminationReason,
    };
  },
  methods: {
    openDetail(id) {
      this.$emit("openDetail", id);
    },
  },
};
</script>

<style scoped>
.align-right {
  margin-right: 1rem;
}
</style>